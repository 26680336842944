import React from "react";
import "./Footer.css";

export default class Footer extends React.Component {
    state = {
        total: null,
        next: null,
        operation: null,
    };


    render() {
        return (
            <div class="footer">
                <div class='footer-text'>
                    <br />
                    Universal Persona<br/>
                    400 4th Street South<br/>
                    Minneapolis<br/>
                    MN 55415<br/>
                    info@universalpersona.com<br />
                    <a href="https://www.myupid.com/universalpersona" class="footer-text">www.myupid.com/universalpersona</a>
                    <br />
                    <br />
                    <div class="disclaimer">
                        The names 'Universal Persona', 'myupid' and all associated sites / branding are copyrighted by esolutionsONE Inc. (24/8/2021)
                    </div>
                    <br />
                     </div>
            </div>
        );
    }
}