import React from "react";
import "./Menubar.css";

export default class Menubar extends React.Component {
state = {
    total: null,
    next: null,
    operation: null,
};


render() {
    return (
        <div class="header">
            <div class = "menu-content">
                <img class="logo" src="uplogo.jpg" alt="logo" width="329px" />
                <div class="menu">
                    <a class="menu-item" href="abc">
                        About
                    </a>
                    <a class="menu-item" href="abc">
                        Roadmap
                    </a>
                    <a class="menu-item" href="abc">
                        My Personas
                    </a>
                    <a class="menu-item" href="abc">
                        Our Partners
                    </a>
                    <a class="menu-item" href="123">
                        Login
                    </a>
                    <a class="menu-item" href="1a2">
                        Logout
                    </a>
               </div>
            </div>
        </div>
    );
}
}