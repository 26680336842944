import React from "react";
import "./App.css";
import Menubar from "./Menubar";
import Footer from "./Footer";

export default class App extends React.Component {
state = {
    total: null,
    next: null,
    operation: null,
};


render() {
    return (
        <div>
            <Menubar />
            <div class="body">
                <div class="body-content">
                    <img src="image2.jpg" class="floaty-right" width="35%" />
                    <div class="body-header1">
                        Taking Back Control of YOU!
                    </div>
                    <div class="body-main">
                        Imagine if you could control what elements of your personal data were shared with organisations;<br />
                        <ul>
                            <li>What information is publically shared? If any?</li>
                            <li>What information is shared with your friends? </li>
                            <li>What information do work co-workers or potential employers have access to? </li>
                        </ul>
                        <p>
                            Universal Persona allows you to create your personal data ONCE, and then decide how you want others to see and use that data.
                        </p>
                        <button class="button">Create Your Universal Persona Now</button>
                    </div>
                </div>
            </div>
            <div class="sidebyside">
                <div class="body-content-50pct">
                    <div class="body-header3">
                        How do I get started?
                    </div>
                    <ol class="body-main2">
                        <li> Register and select your universal persona name.</li>
                        <li> Browse from a selection of data attributes and build your PUBLIC persona.</li>
                        <li> Publish your PUBLIC persona (go take a look at www.myupid.com/youruniversalpersonaID)</li>
                        <li> Build other non public personas by adding and publishing more data attributes, choosing how to share and secure them as you go.</li>
                    </ol>
                </div>
                <div class="body-content-50pct">
                    <div class="body-header3">
                        How SECURE is my data?
                    </div>
                    <div class="body-main2">
                        We take your persona data very seriously, and do everything we can to protect it whilst its in our system or in transit over the internet. We have included the following features to ensure your data stays safe and is only shared with those who you intend to see it.
                        <ul class="body-main2">
                            <li>ENCRYPTION - All your data is encypted whilst in our system.</li>
                            <li>PUBLIC DATA - Only the public persona is publically accessible by default.</li>
                            <li>MARKETING - We will ONLY share the data contained in the marketing persona to our partners. You control this. </li>
                            <li>SHARING - Use secure links and passwords to control access to your other personas. They can be time limited, one time or perpetual</li>
                            <li>AUDITING - All non-public access to your personas will be logged and available for you to review at any time. In addition, notifications can be set to alert you when a persona is viewed.</li>
                            </ul>
                        </div>
                </div>
            </div>
            <div class="body-content">
                <div class="body-header2">
                    You can build personas for a wide variety of use cases:
                </div>
               
               
           
                <div class="cards">

                    <div class="card">
                        <div class="card-header">
                            Public Persona
                        </div>
                        <img class="card-image" src="image2.jpg"/>
                        <div class="card-body">
                            Everyone has a default <b>public</b> persona and its available to anyone who searches for you or knows your UPID (Universal Persona ID)<br /> <br /><b>Share as little or as much</b> information as you wish. Just remember its public!!<br />
                            </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            Marketing Persona
                        </div>
                        <img class="card-image" src="image2.jpg" />
                        <div class="card-body">
                            This is the second mandatory persona (after the public persona), and is used to <b>control the information</b> you wish our marketing partners to have access to.<br /><br /> <b>Take control</b> of the data you want shared for marketing.<br />
                        </div>
                        
                    </div>
                    <div class="card">
                        <div class="card-header">
                            Suggestion: Work Persona
                        </div>
                        <img class="card-image" src="image2.jpg" />
                        <div class="card-body">
                            Let the people at work, or maybe people who you are looking to work for see a controlled view of all the data you want to share with them.<br /><br />Simply set up this profile, apply a <b>secure sharing link</b> to it, and only those with the link will be able to access your details.<br />
                            </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            Suggestion: Dating Persona
                        </div>
                        <img class="card-image" src="image2.jpg" />
                        <div class="card-body">
                            An easy way to control the information you want to give to prospective partners.<br /><br /> To ensure only the right people see this persona, maybe you apply a <b>secure sharing link</b> to it and for added security add a <b>one time password</b>.<br />
                            </div>
                    </div>
   
                </div>
            </div>
            <Footer />
        </div>
    );
}
}